$header-footer-height: $min-header-height + $footer-height;
main{
    padding-top: 10%;
    padding-bottom: 50px + $header-footer-height;
    min-height: 100vh;
    margin-bottom: -$header-footer-height;
    @include transition(all 300ms ease-in);
}
.fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  @include transition(opacity 300ms ease-in);
}
.fade-exit, .fade-exit.fade-exit-active {
    display: none;
}
