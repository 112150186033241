.Project {
  @include flex-direction(row);
  padding: 15px;
  white-space: pre-wrap;
  .ProjectImage{
    background: url('images/ProjectImagePlaceholder.jpeg') no-repeat center;
    @include background-size(contain);
    min-height: 200px;
    margin: 1rem 0;
  }
  .card-text {
    &>p {
      white-space: pre-line;
    }
  }
}
