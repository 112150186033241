.ContactForm {
  textarea, input, .btn-block, .errorlist {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .spinner-border {
    vertical-align: baseline;
  }
  .errorlist {
    border-radius: $border-radius;
  }
}
