#AboutMeDescription {
  img {
    /* It allows to owerlay text */
    float: left;
    margin: 5px 15px;
    border-radius: 15px;
  }
  [title] {
    cursor:help;
  }
}

