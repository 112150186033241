.Home {
  ul {
    list-style-type:none;
    padding-left: 0;
    @include media-breakpoint-up(md){
      li {
        display: inline;
        &:not(:first-child):before{
          content: ' | ';
        }
      }
    }
  }
}