@import url('https://fonts.cdnfonts.com/css/gidole');

$body-color: #e9e9e9;

$primary: #ADD8E6;
$secondary: #aaa;
$success: #006699;
$base-font: 'Gidole', -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$font-family-sans-serif: $base-font;
$headings-font-family: $base-font;
$font-size-base: 1.4rem;

$footer-height: 40px;
$min-header-height: 77px;

:export { minHeaderHeight: $min-header-height; }

$card-border-radius: .6rem;

@mixin background-size($size) {
  -webkit-background-size: $size;
     -moz-background-size: $size;
       -o-background-size: $size;
          background-size: $size;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}

.App {
  padding-top: $min-header-height;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: block;
  padding-bottom: $footer-height;
}

// Required Bootstrap part
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


// Optional Bootstrap part
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/transitions";

@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/images";

// Utilities
@import "node_modules/bootstrap/scss/utilities/background";
@import "node_modules/bootstrap/scss/utilities/borders";
@import "node_modules/bootstrap/scss/utilities/display";
@import "node_modules/bootstrap/scss/utilities/position";
@import "node_modules/bootstrap/scss/utilities/text";
@import "node_modules/bootstrap/scss/utilities/spacing";
@import "node_modules/bootstrap/scss/utilities/flex";
@import "node_modules/bootstrap/scss/utilities/screenreaders";


// App stylesheets
@import './stylesheets/AboutMe.scss';
@import './stylesheets/Footer.scss';
@import './stylesheets/Header.scss';
@import './stylesheets/Main.scss';
@import './stylesheets/home/Home.scss';
@import './stylesheets/portfolio/Portfolio.scss';
@import './stylesheets/portfolio/Project.scss';
@import './stylesheets/ReadMore.scss';
@import './stylesheets/buttons/Button.scss';
@import './stylesheets/hire/HireForm.scss';
@import './stylesheets/hire/HireMe.scss';
@import './stylesheets/hire/SocialLinks.scss';
@import './stylesheets/nav/Navbar.scss';
@import './stylesheets/form_elements/elements.scss';
